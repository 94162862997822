class User {
  constructor(API) {
    this.API = API;
    this.apiPath = process.env.MEMBER_API_URL;
  }


}


export default User;
