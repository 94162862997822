import { makeAutoObservable } from 'mobx';
import history from 'utils/history';
import helpers from 'utils/helpers';
import API from 'api';


class productStore {
	constructor(RootStore) {
		makeAutoObservable(this)
		this.r = RootStore
	};

	products = [];

	fetchProduct = async (productId) => {
		return await this.r.app.asyncWorker(async () => {
			let product = await API.Product.fetchProduct(productId);
			product = product
				? {
					id: product.id || '',
					name: product.name || '',
					shopId: product.shop_id || '',
					shopName: product.shop_name || '',
					price: product.price || 0,
					remain: product.remain === 9999 ? '' : product.remain || 0,
					summary: product.summary || '',
					remark: product.remark || '',
					needEmail: product.need_email || 0,
					needPhone: product.need_phone || 0,
					onSale: product.on_sale || 0,
					cover: product.cover || '',
					content: product.content || [],
					taxName: product.company_name || '',
					taxId: product.tax_id || '',
					selectedSpec: product.specifications.length > 0 ? product.specifications[0].id : 0,
					specifications: product.specifications
						? product.specifications.map(specification => ({
							id: specification.id || '',
							name: specification.spec_name || '',
							remain: specification.spec_remain === 9999 ? '' : specification.spec_remain,
							price: specification.spec_price || 0,
							remark: specification.remark || ''
						}))
						: [],
					url: this.generateProductUrl(product.id),
					quantity: 0, //selected for purchase
					liffId: product.liff_id || '',
				}
				: undefined;

			if (product) {
				product.ogImageUrl = product.cover || '';
				if (product.ogImageUrl) {
					const ogImage = await this.r.app.processImage(product.ogImageUrl);
					product.ogImageWidth = ogImage.width;
					product.ogImageHeight = ogImage.height;
				}
				product.ogDescription = product.summary.replace(/\n/g, ' ').slice(0, 150)
					+ (product.summary.length > 150 ? '...' : '');
			}

			if(product?.liffId){
				//reset and re-initiating line liff sdk
				this.r.auth.line.liffId = product?.liffId
			}

			return product;
		});
	};

	fetchStoreProducts = async (storeId) => {
		return await this.r.app.asyncWorker(async () => {
			let storeProducts = await API.Product.fetchStoreProducts(storeId);
			storeProducts = storeProducts
				? storeProducts.map(storeProduct =>
				({
					id: storeProduct.id || '',
					name: storeProduct.name || '',
					price: storeProduct.price || 0,
					remain: storeProduct.remain || 0,
					summary: storeProduct.summary || '',
					onSale: storeProduct.on_sale || 0,
					createdTime: storeProduct.created_time || '',
					updatedTime: storeProduct.created_time || '',
					cover: storeProduct.cover,
					url: this.generateProductUrl(storeProduct.id),
				})) : [];
				return storeProducts
		});
	};

	fetchProductTickets = async (productId) => {
		return await this.r.app.asyncWorker(async () => {
			let productTickets = await API.Product.fetchProductTickets(productId);
			productTickets = productTickets
				? productTickets.map(ticket =>
				({
					id: ticket.id || '',
					productId: ticket.product_id || '',
					name: ticket.name || '',
					quantity: ticket.quantity || 0,
					remain: ticket.remain || 0,
					cover: ticket.cover || '',
					remark: ticket.remark || '',
					type: ticket.type || 'normal',
					startTime: ticket.start_time || '',
					endTime: ticket.end_time || '',
				})) : [];
				return productTickets
		});
	};

	deleteProductTicket = async (ticketId) => {
    await this.r.app.asyncWorker(async () => {
      await API.Product.deleteProductTicket(ticketId);
    });
  };

	deleteProduct = async (productId) => {
    return await this.r.app.asyncWorker(async () => {
      return await API.Product.deleteProduct(productId);
    });
  };

	setSaleProduct = async (productId) => {
    return await this.r.app.asyncWorker(async () => {
      return await API.Product.setSaleProduct(productId);
    });
  };

	editProduct = async (productId, productData, uploadCoverBase64, specificationsData) => {
    return await this.r.app.asyncWorker(async () => {
			const storeId = this.r.auth.store.id

      if (uploadCoverBase64) {
        const uploadedUrl = await API.Product.uploadPhoto(storeId, uploadCoverBase64);
        if(uploadedUrl) productData.cover = uploadedUrl;
      };

			if (productData.content) {
        for (let paragraph of productData.content) {
          if (paragraph.type === 'image' && paragraph.content.startsWith('data:')) {
            const base64 = paragraph.content;
            const photo = await API.Product.uploadPhoto(storeId, base64);
            paragraph.content = `${photo}\n${photo}`;
          }
        };
      };

      const resultId = await API.Product.editProduct(productId, storeId, productData, specificationsData);
      productId = resultId;

      return productId;
    });
  };

	editProductTicket = async (ticketId, productId, ticketData, uploadCoverBase64) => {
    return await this.r.app.asyncWorker(async () => {
			const storeId = this.r.auth.store.id;
      if (uploadCoverBase64) {
        const uploadedUrl = await API.Product.uploadPhoto(storeId, uploadCoverBase64);
        if(uploadedUrl) ticketData.cover = uploadedUrl;
      };
      const resultId = await API.Product.editProductTicket(ticketId, productId, ticketData);
      return resultId;
    });
  };

	putProductToCart = (product) => {
		this.products = [];

		let cartItem = {
			id: product.id || '',
			name: product.name || '',
			shopId: product.shopId || '',
			shopName: product.shopName || '',
			price: product.price || 0,
			remain: product.remain || 0,
			summary: product.summary || '',
			remark: product.remark || '',
			needEmail: product.needEmail || 0,
			needPhone: product.needPhone || 0,
			cover: product.cover || '',
			url: product.url || '',
			specId: 0,
			specName: '',
			quantity: 1,
		}

		if (product.specifications.length > 0) {
			for (let s of product.specifications) {
				let c = cartItem
				c.specId = s.id
				c.specName = s.name
				c.remark = s.remark
				c.remain = s.amount
				c.price = s.price
				this.products.push(c)
			}
		} else {
			let c = cartItem
			c.specId = 0
			c.specName = ''
			this.products.push(c);
		}
		fbq('track', 'AddToCart');
	}

	setSelectedQuantity = (quantity, productId, specId) => {
		let idx = this.products.findIndex(item => (item.id === productId && item.specId === specId));
		let q = Number(quantity);
		this.products[idx].quantity = q;
	}

	processImage = (url) => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = () => {
				resolve({
					width: img.width,
					height: img.height,
					url: url,
				});
			};
			img.onerror = () => {
				reject();
			};
			img.src = url;
		});
	}

	generateProductUrl = (productId) => {
		let url;
		if (productId) {
			url = `/product/${productId}`;
		}
		return url;
	}
}

export default productStore;