class Exchange {
	constructor(API) {
		this.API = API;
		this.apiPath = process.env.EXCHANGE_API_URL;
	}

	fetchEventShopByCode = (code, shopId) => {
		const url = `${this.apiPath}/ticket/code`;
		const params = {
			code,
			shopId,
		};
		return this.API.httpPost(url, params);
	}

	submitExchange = (ticket, shopId, customerPk) => {
		const url = `${this.apiPath}/ticket`;
		const params = {
			key: this.API.getToken(),
			ticket,
			shopId,
			customerPk
		};
		return this.API.httpPost(url, params);
	}

	fetchMemberTicketByToken = (token) => {
		const url = `${this.apiPath}/ticket/token/${token}`;
		return this.API.httpGet(url);
	}

	shareMemberTicketByToken = (token, newToken, amount) => {
		const url = `${this.apiPath}/ticket/share`;
		const params = {
			key: this.API.getToken(),
			token, 
			newToken, 
			amount
		};
		return this.API.httpPost(url, params);
	}

	rejectMemberTicketByToken = (token) => {
		const url = `${this.apiPath}/ticket/share/reject`;
		const params = {
			key: this.API.getToken(),
			token,
		};
		return this.API.httpPost(url, params);
	}

	fetchMemberTicketByPhone = (phone) => {
		const url = `${this.apiPath}/ticket/phone/${phone}`;
		return this.API.httpGet(url);
	}

	fetchMemberTickets = () => {
		const url = `${this.apiPath}/ticket/list`;
		const params = {
			key: this.API.getToken(),
		};
		return this.API.httpPost(url, params);
	}

	addPrTicket = (shopId, ticketData) => {
		const url = `${this.apiPath}/ticket/pr/add`;
		const params = {
			shopId,
			ticketData
		}
		return this.API.httpPost(url, params);
	}

	getPrTickets = (productId) => {
		const url = `${this.apiPath}/ticket/pr/list`;
		const params = {
			key: this.API.getToken(),
			productId,
		}
		return this.API.httpPost(url, params);
	}

	takePrTicket = (ticketData, lineUserId) => {
		const url = `${this.apiPath}/ticket/pr/take`;
		const params = {
			key: this.API.getToken(),
			lineUserId,
			ticketData
		}
		return this.API.httpPost(url, params);
	}

	takePrTicketSuccess = (ticketData, lineUserId, shopId) => {
		const url = `${this.apiPath}/ticket/pr/success`;
		const params = {
			key: this.API.getToken(),
			lineUserId,
			ticketData,
			shopId
		}
		return this.API.httpPost(url, params);
	}
}

export default Exchange;
