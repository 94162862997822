import { makeAutoObservable } from 'mobx';
import history from 'utils/history';
import helpers from 'utils/helpers';
import API from 'api';


class UserStore {
	constructor(RootStore) {
		makeAutoObservable(this)
		this.r = RootStore
	};

	checkLineProvider = async () => {
		return await this.r.app.asyncWorker(async () => {
			const liffId = this.r.auth.line.liffId || process.env.LINE_LIFF_ID
			const result = await API.Auth.checkLineProvider(liffId);
			return result
		});
	};

	verify = async () => {
		await this.r.app.asyncWorker(async () => {
			const auth = await API.Auth.verify();
			this.setUserAuthState(auth)
			if (auth.shopId) {
				const store = await API.Shop.fetchShopSettings(auth.shopId);
				this.setShopAuthState(store)
			}
		});
	};

	setUserAuthState = (auth) => {
		this.r.auth.user.id = auth.id;
		this.r.auth.user.name = (auth.nickname || '').replace('()', '').replace('(', '（').replace(')', '）');
		this.r.auth.user.avatarUrl = auth.avatar || '';
		this.r.auth.user.email = auth.email;
		this.r.auth.user.phone = auth.phone;
		this.r.auth.authenticated = auth.authenticated === 1;
		this.r.auth.validUser = (!!auth.id && auth.authenticated === 1);
		this.r.auth.isStore = !!auth.shopId || false;
		if (auth.employee) {
			this.r.auth.store.id = auth.shopId;
			this.r.auth.store.employeeId = auth.id;
			this.r.auth.isEmployee = true;
			this.r.auth.user.employeeType = auth.employeeType || 'employee'; //employee || manager
		}
	}

	setShopAuthState = (auth) => {
		this.r.auth.store.id = auth.shop_id || '';
		this.r.auth.store.name = auth.shop_name || '';
		this.r.auth.store.signed = !!auth.is_signed || false;
		this.r.auth.store.company = auth.company_name || '';
		this.r.auth.store.chainId = auth.chain_id || '';
		this.r.auth.store.taxId = auth.tax_id || '';
		this.r.auth.store.liffId = auth.liff_id ||'';
		this.r.auth.store.planType = auth.plan_type || '';
		this.r.auth.store.planValid = auth.is_valid || false;
		this.r.auth.validStore = (!!auth.shop_id && !!auth.is_signed && !!auth.is_valid) || false;
		if(auth.liff_id){
			this.r.auth.line.liffId = auth.liff_id;
		}
	}

	signinWithLine = async () => {
		await this.r.app.asyncWorker(async () => {
			if (liff.isInClient()) {
				this.loginWithLine()
			} else {
				let currentUrl = window.location.href 
				const liffId = this.r.auth.line.liffId || process.env.LINE_LIFF_ID

				if(currentUrl.includes('?')){
					if(!currentUrl.includes('liffid')){
						currentUrl = currentUrl + `&liffid=${liffId}`
					}
				}else{
					currentUrl = currentUrl + `?liffid=${liffId}`
				}
				liff.login({ redirectUri: currentUrl })
			}
		});
	};

	checkLineLogin = (message) => {
		this.r.ui.popup.message = message;
		this.r.ui.popup.callback = () => {
			this.signinWithLine();
		}
		this.r.ui.popup.current = 'action';
	}

	signinAtLocalhostAsDeveloper = () => {
		//Development usage
		//localhost
		if (window.location.hostname == 'localhost') {
			API.setToken('17dcefb0-2f92-11ee-a556-bfd625da3a9d')
		}
		//Development usage
	}

	loginWithLine = async () => {
		await this.r.app.asyncWorker(async () => {
			//liff.init() automatically calls liff.login()
			if (liff.isLoggedIn()) {
				const lineUserToken = await liff.getDecodedIDToken();
				if (lineUserToken) {
					const userData = {
						email: lineUserToken.email,
						userId: lineUserToken.sub, //line user id is related to provider
						nickname: lineUserToken.name,
						pictureUrl: lineUserToken.picture,
						channelId: lineUserToken.aud //line provider channel 
					}
					const token = await API.Auth.signinWithLineLiff(userData);
					if (token) {
						API.setToken(token);
						await this.verify();
					}
				}
			}
		});
	}

	resetLineLogin = (message) => {
		this.r.ui.popup.message = message;
		this.r.ui.popup.callback = async() => {
			if (liff.isLoggedIn()) {
				liff.logout();
			}
			API.setToken(undefined);
			const origineUrl = window.location.href
			const liffUrl = await liff.permanentLink.createUrlBy(origineUrl)
			window.location.replace(liffUrl);
		}
		this.r.ui.popup.current = 'action';
	}

	signout = async () => {
		await this.r.app.asyncWorker(async () => {
			const logout = await API.Auth.logout();
			API.setToken(undefined);
			if (logout) {
				const origineUrl = window.location.href
				const liffUrl = await liff.permanentLink.createUrlBy(origineUrl)
				if (liff.isLoggedIn()) {
					liff.logout();
				}
				window.location.href = liffUrl; 
			}
		});
	};

	fetchUserRememberedCreditCard = async (TapPayAppID) => {
		return await this.r.app.asyncWorker(async () => {
			console.log('TapPayAppID for fecth card: ', TapPayAppID)
			let lastFour = await API.Payment.getUserCreditCard(TapPayAppID);
			this.r.auth.financial.creditCard.lastFour = lastFour || ''
			return lastFour;
		});
	};

}

export default UserStore;