class BAZAAR {
	constructor(API) {
		this.API = API;
		this.apiPath = process.env.BAZAAR_API_URL;
	}

	importBazaarProductFromMall = (shopId) => {
		const url = `${this.apiPath}/import`;
		const params = {
			key: this.API.getToken(),
			shopId
		};
		return this.API.httpPost(url, params);
	}

	fetchStoreProducts = async (storeId) => {
		const url = `${this.apiPath}/products/${storeId}`;
		return this.API.httpGet(url);
	}

	deleteProduct = (productId) => {
		const url = `${this.apiPath}/product/${productId}`;
		return this.API.httpDelete(url);
	}

	fetchProduct = (productId) => {
		const url = `${this.apiPath}/product/${productId}`;
		return this.API.httpGet(url);
	}

	addProduct = async (productId, storeId, productData) => {
		const url = `${this.apiPath}/product`;
		const params = {
			key: this.API.getToken(),
			shopId: storeId,
			productId: productId,
			productData: productData,
		};
		return this.API.httpPost(url, params);
	}

	createOrder = (shopId, order) => {
		const url = `${this.apiPath}/order/create`;
		const params = {
			key: this.API.getToken(),
			shopId,
			order
		};
		return this.API.httpPost(url, params);
	}

	cancelOrder = async (paymentId, shopId) => {
		const url = `${this.apiPath}/order/cancel`;
		const params = {
			key: this.API.getToken(),
			shopId,
			paymentId,
		};
		return this.API.httpPost(url, params);
	}

	fetchOrderHistory = (shopId, query, page) => {
		const url = `${this.apiPath}/order/history`;
		const params = {
			key: this.API.getToken(),
			shopId,
			query, page
		};
		return this.API.httpPost(url, params);
	}

}

export default BAZAAR;
