class Shop {
	constructor(API) {
		this.API = API;
		this.apiPath = process.env.SHOP_API_URL;
	}

	fetchShopSettings = (shopId) => {
		const url = `${this.apiPath}/${shopId}`;
		return this.API.httpGet(url);
	}

	fetchStoreBranches = (shopId) => {
		const url = `${this.apiPath}/branch/list/${shopId}`;
		return this.API.httpGet(url);
	}

	fetchShopEmployees = (shopId) => {
		const url = `${this.apiPath}/employee/list/${shopId}`;
		return this.API.httpGet(url);
	}

	fetchShopPartners = (shopId) => {
		const url = `${this.apiPath}/partners/list/${shopId}`;
		return this.API.httpGet(url);
	}

	updateShopPixelId = (shopId, pixelId) => {
    const url = `${this.apiPath}/setting/pixel`;
    const params = {
      key: this.API.getToken(),
			shopId,
      pixelId,
    };
    return this.API.httpPut(url, params);
  }

	editShopCustomerEmailSettings = (shopId, mail, title) => {
    const url = `${this.apiPath}/setting/email`;
    const params = {
      key: this.API.getToken(),
			shopId,
      mail,
			title
    };
    return this.API.httpPut(url, params);
  }

	addShopEmployee = (shopId, employeeType) => {
    const url = `${this.apiPath}/employee/create`;
    const params = {
      key: this.API.getToken(),
			shopId,
			employeeType
    };
    return this.API.httpPost(url, params);
  }

	toggleShopEmployeeValid = (shopId, employeePk, employeeType, lineNotification, toValid) => {
    const url = `${this.apiPath}/employee/auth`;
    const params = {
      key: this.API.getToken(),
			shopId,
      employeePk,
			employeeType,
			lineNotification,
			toValid
    };
    return this.API.httpPost(url, params);
  }

	addShopPartner = (shopId, sourceId) => {
    const url = `${this.apiPath}/partners/create`;
    const params = {
      key: this.API.getToken(),
			shopId, 
			sourceId
    };
    return this.API.httpPost(url, params);
  }

	editShopPartner = (shopId, partnerId, partnerData) => {
    const url = `${this.apiPath}/partners/auth`;
    const params = {
      key: this.API.getToken(),
			shopId, 
			partnerId, 
			partnerData
    };
    return this.API.httpPost(url, params);
  }

	removeShopEmployee = (shopId, employeePk) => {
    const url = `${this.apiPath}/employee/auth`;
    const params = {
      key: this.API.getToken(),
			shopId,
      employeePk,
    };
    return this.API.httpDelete(url, params);
  }

	removeShopPartner = (shopId, partnerId) => {
    const url = `${this.apiPath}/partners/auth`;
    const params = {
      key: this.API.getToken(),
			shopId,
      partnerId,
    };
    return this.API.httpDelete(url, params);
  }

	signupShop = (signup) => {
    const url = `${this.apiPath}/signup`;
    const params = {
      key: this.API.getToken(),
			signup,
    };
    return this.API.httpPost(url, params);
  }

	fetchShopUsers = (shopId) => {
    const url = `${this.apiPath}/users`;
    const params = {
      key: this.API.getToken(),
			shopId,
    };
    return this.API.httpPost(url, params);
  }
}

export default Shop;
