import Auth from './Auth';
import User from './User';
import Payment from './Payment';
import Product from './Product';
import Exchange from './Exchange';
import Shop from './Shop';
import Bazaar from './Bazaar';
import Subscription from './Subscription';

class API {
  constructor() {
    this.Auth = new Auth(this);
    this.User = new User(this);
    this.Shop = new Shop(this);
    this.Payment = new Payment(this);
    this.Product = new Product(this);
    this.Exchange = new Exchange(this);
    this.Bazaar = new Bazaar(this);
    this.Subscription = new Subscription(this);
  }

  fetchOptions = {
    headers: { 'content-type': 'application/json' },
  }

  setToken = (token) => {
    if(token) {
      window.localStorage.setItem('taketla_token', token);
    }
    else {
      window.localStorage.removeItem('taketla_token');
    }
  }

  getToken = () => {
    const token = window.localStorage.getItem('taketla_token');
    if(token) return token;
    else throw { status: -1 };
  }

  dataHandler = data => {
    if(data.status === 200) {
      return data.message;
    }
    else {
      throw { 
        status: data.status, 
        message: data.message
      };
    }
  }

  createTimeoutSignal = (timeout) => {
    if(window.AbortController && timeout) {
      const controller = new AbortController();
      const signal = controller.signal;

      setTimeout(() => {
        controller.abort();
      }, timeout);

      return signal;
    }
  }

  httpGet = (url, timeout) => {
    return fetch(url, {
      ...this.fetchOptions,
      method: 'GET',
      signal: this.createTimeoutSignal(timeout),
    })
    .then(resp => resp.json())
    .then(this.dataHandler)
  }

  httpPost = (url, params, timeout) => {
    return fetch(url, {
      ...this.fetchOptions,
      method: 'POST',
      body: JSON.stringify(params),
      signal: this.createTimeoutSignal(timeout),
    })
    .then(resp => resp.json())
    .then(this.dataHandler)
  }

  httpPut = (url, params, timeout) => {
    return fetch(url, {
      ...this.fetchOptions,
      method: 'PUT',
      body: JSON.stringify(params),
      signal: this.createTimeoutSignal(timeout),
    })
    .then(resp => resp.json())
    .then(this.dataHandler)
  }

  httpDelete = (url, params, timeout) => {
    return fetch(url,{
      ...this.fetchOptions,
      method: 'DELETE',
      body: JSON.stringify(params),
      signal: this.createTimeoutSignal(timeout),
    })
    .then(resp => resp.json())
    .then(this.dataHandler)
  }
}



export default new API();
