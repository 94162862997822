import React, { useEffect, lazy, Suspense } from 'react';
import loadable from '@loadable/component'
import { Switch, Route, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import { Helmet } from "react-helmet";
import history from 'utils/history';
import helpers from 'utils/helpers';

const PartialLoading = loadable(() => import('./PartialLoading'));
const PartialHeader = loadable(() => import('./PartialHeader'));
const PartialShopPanel = loadable(() => import('./PartialShopPanel'));
const PartialMain = loadable(() => import('./PartialMain'));
const PartialFooter = loadable(() => import('./PartialFooter'));
const PartialToast = loadable(() => import('./PartialToast'));
const BarAppTab = loadable(() => import('components/BarAppTab'));
const DrawerNav = loadable(() => import('components/DrawerNav'));
const DrawerMenu = loadable(() => import('components/DrawerMenu'));
const DrawerShop = loadable(() => import('components/DrawerShop'));
const DrawerShopMenu = loadable(() => import('components/DrawerShopMenu'));

//Lazy loading pages
const PageHome = lazy(() => import('containers/PageHome'));
const PageProduct = lazy(() => import('containers/PageProduct'));
const PageProductEdit = lazy(() => import('containers/PageProductEdit'));
const PageCart = lazy(() => import('containers/PageCart'));
const PageTicket = lazy(() => import('containers/PageTicket'));
const PageTapPay = lazy(() => import('containers/PageTapPay'));
const PageSelectPayment = lazy(() => import('containers/PageSelectPayment'));
const PageUserFinance = lazy(() => import('containers/PageUserFinance'));
const PagePolicy = lazy(() => import('containers/PagePolicy'));
const PageOrderDetail = lazy(() => import('containers/PageOrderDetail'));
const PageFakeLoading = lazy(() => import('containers/PageFakeLoading'));
const PageStoreProducts = lazy(() => import('containers/PageStoreProducts'));
const PageStoreFinance = lazy(() => import('containers/PageStoreFinance'));
const PageStoreExchange = lazy(() => import('containers/PageStoreExchange'));
const PagePrTicket = lazy(() => import('containers/PagePrTicket'));
const PageBazaarProducts = lazy(() => import('containers/PageBazaarProducts'));
const PageBazaarProductEdit = lazy(() => import('containers/PageBazaarProductEdit'));
const PageBazaarCreateOrder = lazy(() => import('containers/PageBazaarCreateOrder'));
const PageBazaarOrderHistory = lazy(() => import('containers/PageBazaarOrderHistory'));
const PageStoreSettings = lazy(() => import('containers/PageStoreSettings'));
const PageStoreContract = lazy(() => import('containers/PageStoreContract'));
const PageEmployeeAuthRequest = lazy(() => import('containers/PageEmployeeAuthRequest'));
const PageStoreSignup = lazy(() => import('containers/PageStoreSignup'));
const PageStoreBusinessGuide = lazy(() => import('containers/PageStoreBusinessGuide'));
const PageStore = lazy(() => import('containers/PageStore'));

const PopupMessage = loadable(() => import('Popup/PopupMessage'));
const PopupAction = loadable(() => import('Popup/PopupAction'));
const WidgetLightbox = loadable(() => import('Widgets/WidgetLightbox'));


const App = () => {
	const { mobx } = useStores();
	const location = useLocation();
	const auth = mobx.auth

	useEffect(() => {
		const { queryParamsAct, setInitialized, setLineLiffId } = mobx.app;
		const { verify, checkLineProvider } = mobx.user;
		const queryParams = helpers.queryParams();
		//set line liff id
		if (queryParams.liffid) {
			setLineLiffId(queryParams.liffid)
		}
		//set affiliate id cookie
		if (queryParams.aid) {
			const days = 30;
			helpers.setCookie('aid', queryParams.aid, 24 * 60 * 60 * days, '/');
			history.replace(history.location.pathname);
		}
		if (queryParams.act || queryParams.app) {
			queryParamsAct(queryParams);
			history.replace(history.location.pathname);
		}
		setInitialized();
		if (!window.hotReloading) {
			//check user provider == line liff provider
			setTimeout(() => {
				//checkLineProvider()
			}, 6000);
			//login
			verify();
		}
	}, [])

	useEffect(() => {
		const { initiateLineLiff } = mobx.app
		const { checkLineProvider } = mobx.user
		//reset line liff
		const reset = true
		initiateLineLiff(reset);
		setTimeout(() => {
			//checkLineProvider()
		}, 6000);
	}, [auth.line.liffId])

	useEffect(() => {
		const { setUI } = mobx.app;
		if (liff.isInClient()) {
			setUI('webview', true)
		}
		setTimeout(() => {
			const pathname = location.pathname;
			if (pathname == '/') {
				history.push('/main')
			}
		}, 3300);
	}, [])


	return (
		<>
			<Helmet>
				<title>{process.env.APP_TITLE}</title>
				<meta name="description" content={process.env.APP_DESCRIPTION} />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={process.env.APP_TITLE} />
				<meta property="og:description" content={process.env.APP_DESCRIPTION} />
				<meta property="og:image" content={`${process.env.CDN_URL}/taketla/taketla-og.jpg`} />
				<meta property="og:ttl" content="2419200" />
				<link rel="canonical" href={window.location.href} />
			</Helmet>
			<Route component={PartialHeader} />
			<Route>
				<PartialMain>
					<Suspense fallback={<PartialLoading />}>
						<PartialShopPanel />
						<Switch>
							{/* ":lineliff?/" set for line liff redirect url  */}
							<Route exact path={`/`} component={PageFakeLoading} />
							<Route exact path={`/main`} component={PageHome} />
							<Route exact path={`/member/finance/:tab?`} component={PageUserFinance} />
							<Route exact path={`/product/edit/:id?/`} component={PageProductEdit} />
							<Route exact path={`/product/:id/`} component={PageProduct} />
							<Route exact path={`/ticket/:token/`} component={PageTicket} />
							<Route exact path={`/cart`} component={PageCart} />
							<Route exact path={`/tappay`} component={PageTapPay} />
							<Route exact path={`/select-payment`} component={PageSelectPayment} />
							<Route exact path={`/order/:paymentId`} component={PageOrderDetail} />
							<Route exact path={`/shop/info/:id/`} component={PageStore} />
							<Route exact path={`/shop/products`} component={PageStoreProducts} />
							<Route exact path={`/shop/finance`} component={PageStoreFinance} />
							<Route exact path={`/shop/signup/:step`} component={PageStoreSignup} />
							<Route exact path={`/shop/exchange/:step?`} component={PageStoreExchange} />
							<Route exact path={`/shop/settings/:tab?/:step?`} component={PageStoreSettings} />
							<Route exact path={`/prticket`} component={PagePrTicket} />
							<Route exact path={`/policy`} component={PagePolicy} />
							<Route exact path={`/bazaar/products`} component={PageBazaarProducts} />
							<Route exact path={`/bazaar/product/edit/:id?`} component={PageBazaarProductEdit} />
							<Route exact path={`/bazaar/create-order/:step?`} component={PageBazaarCreateOrder} />
							<Route exact path={`/bazaar/order-history/:step?`} component={PageBazaarOrderHistory} />
							<Route exact path={`/shop/contract`} component={PageStoreContract} />
							<Route exact path={`/employee/auth/:shop?`} component={PageEmployeeAuthRequest} />
							<Route exact path={`/business`} component={PageStoreBusinessGuide} />
							<Route path={`*`} component={() => { window.location.href = '/' }} />
						</Switch>
					</Suspense>
				</PartialMain>
			</Route>
			<Route component={DrawerNav} />
			<Route component={DrawerMenu} />
			<Route component={DrawerShop} />
			<Route component={DrawerShopMenu} />
			<Route component={BarAppTab} />
			<Route component={PartialFooter} />
			<Suspense fallback={<PartialLoading />}>
				<PopupMessage />
				<PopupAction />
				<WidgetLightbox />
				<PartialToast />
			</Suspense>
			<PartialLoading />
		</>
	);
}

export default observer(App);
