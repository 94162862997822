import { makeAutoObservable, toJS } from 'mobx';
import history from 'utils/history';
import helpers from 'utils/helpers';
import API from 'api';


class orderStore {
	constructor(RootStore) {
		makeAutoObservable(this)
		this.r = RootStore
	};

	tappayOrder = null;

	prepareOrder = async (order) => {
		/**
		 * @param {Object} order is An object representing an order for purchase.
		 * {
				email: email,
				aid: '',
				promotionCode: '',
				invoice: {
					type: B2B',
					taxId: '',
					taxName: '',
				},
				total: 0,
				method:'CREDIT',
				items: [arr]
			} 
		 * 
		 */
		this.tappayOrder = order;
		const shop = await this.r.shop.fetchShopSettings(order?.shopId);
		if(shop){
			//customer payment settings
			this.tappayOrder.customerPayment = shop.plan.customerPayment
			if(shop.plan.customerPayment){
				this.tappayOrder.TAPPAY_APP_ID = shop.tappaySettings.appId || process.env.TAPPAY_APP_ID
				this.tappayOrder.TAPPAY_APP_KEY = shop.tappaySettings.appKey || process.env.TAPPAY_APP_KEY
			}
		}
		if(!!shop.plan.customerPayment){
			history.push('/tappay')
		}
		else history.push('/select-payment')
	}

	checkPromotionCode = async (code, shopId) => {
		return await this.r.app.asyncWorker(async () => {
			let promotionCodeRule = await API.Payment.getPromotionCodeRule(shopId, code);
			promotionCodeRule = promotionCodeRule
				? {
					value: promotionCodeRule.code,
					type: promotionCodeRule.type || '',
					threshold: promotionCodeRule.threshold || 0,
					discount: promotionCodeRule.discount || 0,
					invalid: false
				} : undefined;
			return promotionCodeRule
		});
	};

	submitTapPayPayment = async (tappayOrder, tappayData) => {
		await this.r.app.asyncWorker(async () => {
			this.r.ui.popup.message = '系統處理中，請稍候。';
			this.r.ui.popup.current = 'message';
			const result = await API.Payment.submitTapPayPayment(tappayOrder, tappayData);
			console.log('payment result: ', result)
			//result.paymentId
			if (result.paymentMsg === 'SUCCESS') {
				//post to tappay success api
				const done = await API.Payment.successTapPayPayment(result.paymentId)
				if(done){
					if(!!result.threeDomainSecure) {
						window.location.href = result.paymentUrl;
					} else {
						this.r.ui.popup.message = '付款成功'
						this.r.ui.popup.callback = () => history.push('/member/finance/tickets');
						this.r.ui.popup.current = 'message';
						fbq('track', 'Purchase', { value: tappayOrder.total, currency: 'TWD' }, { eventID: tappayData.prime });
					}
				}
			}
		});
	};

	submitLinePayPayment = async (tappayOrder, tappayData) => {
		return await this.r.app.asyncWorker(async () => {
			const context = liff.getContext();
			const lineUserToken = await liff.getDecodedIDToken();
			const lineData = {
				userId: context?.userId || lineUserToken?.sub || '',
				liffId: context?.liffId || ''
			}
			this.r.ui.popup.message = '系統處理中，請稍候。';
			this.r.ui.popup.current = 'message';
			const result = await API.Payment.submitLinePayPayment(tappayOrder, tappayData, lineData);
			if (result.paymentMsg === 'SUCCESS') {
				fbq('track', 'Purchase', { value: tappayOrder.total, currency: 'TWD' }, { eventID: tappayData.prime });
				return result.paymentUrl
			}
			return null
		});
	};

	submitShopMonthlyBillPayment = async (billId) => {
		await this.r.app.asyncWorker(async () => {
			const result = await API.Subscription.payShopMonthlyBillDirectly(this.r.auth.store.id, billId);
			if (result) {
				this.r.ui.popup.message = '付款成功，請重新整理'
				this.r.ui.popup.current = 'message';
			}
		});
	};

	getFreeTicket = async (order) => {
		return await this.r.app.asyncWorker(async () => {
			const context = liff.getContext();
			const lineData = {
				userId: context?.userId || '',
				liffId: context?.liffId || ''
			}
			this.r.ui.popup.message = '系統處理中，請稍候。';
			this.r.ui.popup.current = 'message';
			const result = await API.Payment.submitFreeTicketPayment(order, lineData);
			if (result) {
				fbq('track', 'Purchase', { value: 0, currency: 'TWD' });
				this.r.ui.popup.message = '已成功領取'
				this.r.ui.popup.callback = () => history.push('/member/finance/tickets');
				this.r.ui.popup.current = 'message';
			}
			return result;
		});
	};

	fetchOrder = async (paymentId) => {
		return await this.r.app.asyncWorker(async () => {
			let order = await API.Payment.fetchOrder(paymentId);
			order = order
				? {
					paymentId: order.payment_id || '',
					shopId: order.shop_id || '',
					shopName: order.shop_name || '',
					remark: order.remark || '',
					paidAmt: order.amount || 0,
					createdTime: helpers.getGmtLocalTime(new Date(order.created_time) || '', 8) || '',
					wayPayment: order.payment_method || 'NONE',
					status: order.status || 0,
					billStatus: order.bill_status || '',
					amount: order.amount || '',
					remark: order.remark || '',
					recipientName: order.recipient_name || '',
					recipientPhone: order.recipient_phone || '',
					recipientEmail: order.recipient_email || '',
					invoice: {
						number: order.invoice_number || '',
						status: order.invoice_status || '',
					},
					billId: order.bill_id || '',
					items: order.items
						? order.items.map(item => ({
							cover: item.cover || '',
							productId: item.product_id || '',
							productName: item.product_name || '',
							specificationId: item.specification_id || 0,
							specificationName: item.specification_name || '',
							quantity: item.quantity || 0,
							price: item.price || 0,
						})) : [],
					tickets: order.tickets
						? order.tickets.map(t => ({
							memberTicketId: t.id || '',
							ticketId: t.ticket_id || '',
							name: t.name || '',
							quantity: t.quantity || 0,
							remain: t.remain || 0,
							remark: t.remark || '',
							cover: t.cover || '',
							token: t.token || '',
							isValid: t.is_valid || 0,
							logTime: t.log_time || '',
							startTime: t.start_time || '',
							endTime: t.end_time || '',
						})) : []
				}
				: null;
			return order
		});
	};


	fetchUserOrders = async () => {
		return await this.r.app.asyncWorker(async () => {
			let paymentOrders = await API.Payment.fetchUserOrders();
			paymentOrders = paymentOrders
				? paymentOrders.map(po => ({
					paymentId: po.payment_id || '',
					shopId: po.shop_id || '',
					shopName: po.shop_name || '',
					paidAmt: po.amount || 0,
					status: po.status || 0,
					remark: po.remark || '',
					createdTime: helpers.getGmtLocalTime(new Date(po.created_time) || '', 8) || '',
					wayPayment: po.payment_method || 'NONE',
					items: po.items
						? po.items.map(item => ({
							cover: item.cover || '',
							productId: item.product_id || '',
							productName: item.product_name || '',
							specificationId: item.specification_id || 0,
							specificationName: item.specification_name || '',
							quantity: item.quantity || 0,
							price: item.price || 0,
						})) : [],
					tickets: po.tickets
						? po.tickets.map(t => ({
							memberTicketId: t.id || '',
							ticketId: t.ticket_id || '',
							name: t.name || '',
							quantity: t.quantity || 0,
							remain: t.remain || 0,
							remark: t.remark || '',
							cover: t.cover || '',
							token: t.token || '',
							isValid: t.is_valid || 0,
							isShared: t.is_shared || 0,
							logTime: t.log_time || '',
							startTime: t.start_time || '',
							endTime: t.end_time || '',
						})) : []
				}))
				: [];
			return paymentOrders
		});
	};


	fetchUserTickets = async () => {
		return await this.r.app.asyncWorker(async () => {
			let tickets = await API.Exchange.fetchMemberTickets();
			tickets = tickets
				? tickets.map(t => ({
					memberTicketId: t.id || '',
					ticketId: t.ticket_id || '',
					paymentId: t.payment_id || '',
					name: t.name || '',
					shopName: t.shop_name || '',
					quantity: t.quantity || 0,
					remain: t.remain || 0,
					remark: t.remark || '',
					cover: t.cover || '',
					token: t.token || '',
					isValid: t.is_valid || 0,
					isShared: t.is_shared || 0,
					logTime: t.log_time || '',
					startTime: t.start_time || '',
					endTime: t.end_time || '',
				}))
				: [];
			return tickets
		});
	};


	fetchShopOrders = async (query, page) => {
		return await this.r.app.asyncWorker(async () => {
			let logs = await API.Payment.fetchShopOrders(this.r.auth.store.id, query, page);
			logs = logs
				? logs.map(log => ({
					paymentId: log.payment_id || '',
					status: log.status || 0,
					cost: log.amount || '',
					billStatus: log.bill_status || '',
					amount: log.amount || '',
					remark: log.remark || '',
					createdTime: helpers.getGmtLocalTime(new Date(log.created_time) || '', 8) || '',
					billId: log.bill_id || '',
					items: [],
					invoice: {
						number: log.invoice_number || '',
						status: log.invoice_status || '',
					},
				}))
				: null;
			return logs
		});
	};

	fetchShopMonthlyBills = async () => {
		return await this.r.app.asyncWorker(async () => {
			let bills = await API.Subscription.fetchShopMonthlyBill(this.r.auth.store.id);
			bills = bills
				? bills.map(bill => ({
					id: bill.bill_id || '', 
					planType: bill.plan_type || 'counting', 
					monthlyFee: bill.monthly_fee || '8800',
					year: bill.year || '',
					month: bill.month || '',
					ticketFreeTier: bill.free_monthly_ticket_amount || '', 
					perTicketFee: bill.per_ticket_fee || '', 
					ticketAmt: bill.ticket_amount || '',
					ticketFee: bill.ticket_fee || '', 
					revenue: bill.revenue || '', 
					paymentFeePercentage: bill.payment_fee_percentage || '',
					paymentFee: bill.payment_fee || '', 
					perInvoiceFee: bill.per_invoice_fee || '', 
					invoiceAmt: bill.invoice_amount || '', 
					invoiceFee: bill.invoice_fee || '', 
					custimerPayment: bill.customer_payment || '',
					custimerInvoice: bill.customer_invoice || '',
					invoiceService: bill.invoice_service || '',
					isPaid: bill.is_paid || 0,
					isSent: bill.is_sent || 0,
					total: bill.total || 0, 
					paidTime: bill.paid_time || '', 
					createdTime: bill.created_time || '', 
					recTradeId: bill.rec_trade_id || '',
					invoiceNumber: bill.invoice_number || '',
					invoiceRandomNumber: bill.random_num || '',
				}))
				: null;
			return bills
		});
	};

	downloadShopOrders = async (query, page) => {
		return await this.r.app.asyncWorker(async () => {
			let logs = await API.Payment.fetchShopOrders(this.r.auth.store.id, query, page);
			logs = logs
				? logs.map(log => ({
					'訂單編號': log.payment_id || '--',
					'訂單狀態': log.status || '--',
					'訂單金額': log.amount || '--',
					'結算狀態': log.bill_status ? '已結算' : '未結算',
					'成立時間': helpers.getGmtLocalTimeString(new Date(log.created_time) || '', 8, 'YYYY-MM-DD hh:mm:ss') || '--',
					'用戶名稱': (log.recipient_name || '').replace(/"|,/g, '') || '--',
					'用戶手機': log.recipient_phone || '--',
					'用戶 Email': log.recipient_email || '--',
					'發票號碼': log.invoice_number || '--',
					'未核銷數量': log.total_remain || '--',
					'結算單編號': log.bill_id || '--',
					'訂單商品': log.item_list || '--',
					'訂單備註': (log.remark || '').replace(/"|,/g, '') || '--',
				}))
				: null;
			return logs
		});
	};

	fetchShopTicketLog = async (query, page) => {
		return await this.r.app.asyncWorker(async () => {
			let logs = await API.Payment.fetchShopTicketLogs(this.r.auth.store.id, query, page);
			logs = logs
				? logs.map(log => ({
					//id: log.id || '',
					//ticketId: log.ticket_id || '',
					//memberTicketId: log.member_ticket_id || '',
					paymentId: log.payment_id || '',
					ticketName: log.name || '',
					logTime: helpers.getGmtLocalTime(new Date(log.log_time) || '', 8) || '',
					amount: log.amount || '',
					remain: log.remain || '',
					userName: log.nickname || '',
				}))
				: null;
			return logs
		});
	};

	fetchUserTicketLog = async () => {
		return await this.r.app.asyncWorker(async () => {
			let logs = await API.Payment.fetchUserTicketLogs();
			logs = logs
				? logs.map(log => ({
					paymentId: log.payment_id || '',
					ticketName: log.name || '',
					logTime: helpers.getGmtLocalTime(new Date(log.log_time) || '', 8) || '',
					amount: log.amount || '',
					remain: log.remain || '',
					shopName: log.shop_name || '',
				}))
				: [];
			return logs
		});
	};

	downloadShopTicketLog = async (query, page) => {
		return await this.r.app.asyncWorker(async () => {
			let logs = await API.Payment.fetchShopTicketLogs(this.r.auth.store.id, query, page);
			logs = logs
			? logs.map(log => ({
					'訂單編號': log.payment_id || '--',
					'票券名稱': log.name || '--',
					'核銷數量': log.amount || '--',
					'剩餘數量': log.remain || '--',
					'核銷時間': helpers.getGmtLocalTimeString(new Date(log.log_time) || '', 8, 'YYYY-MM-DD hh:mm:ss') || '--',
					'用戶名稱': (log.nickname || '').replace(/"|,/g, '') || '--',
					'用戶手機': log.phone || '--',
					'用戶 Email': log.email || '--',
				}))
				: null;
			return logs
		});
	};


	fetchCertainTicket = async (ticketToken) => {
		await this.r.app.asyncWorker(async () => {
			let ticket = await API.Product.fetchTicketByToken(ticketToken);
			ticket = {
				userPk: ticket.user_pk || '',
				id: ticket.id || '',
				shop_id: ticket.shop_id,
				shop_name: ticket.shop_name || '',
				product_id: ticket.product_id,
				product_name: ticket.name || '',
				spec_name: ticket.spec_name || '',
				amount: ticket.amount || 0,
				used_amount: ticket.used_amount || 0,
				expiry: ticket.expiry_date || '--',
				remark: ticket.special_remark || '',
				image: ticket.image_src || '',
				token: ticket.token || '',
				eventId: ticket.event_id || '',
			};

			let ticketList = [];
			ticketList.push(ticket);
			this.ticketList = ticketList;
		});
	};

	cancelOrder = async (paymentId) => {
		return await this.r.app.asyncWorker(async () => {
			const shopId = this.r.auth.store.id
			let result = await API.Payment.cancelOrder(paymentId, shopId);
			if (result) {
				this.r.ui.popup.message = '訂單取消與退款成功'
				this.r.ui.popup.current = 'message';
			}
			return result
		});
	};


	invalidInvoice = async (invoiceNumber) => {
		return await this.r.app.asyncWorker(async () => {
			const shopId = this.r.auth.store.chainId || this.r.auth.store.id //consider chain store
			let result = await API.Payment.invalidInvoice(shopId, invoiceNumber);
			if (result) {
				this.r.ui.popup.message = result
				this.r.ui.popup.current = 'message';
			}
			return result
		});
	};
}

export default orderStore;