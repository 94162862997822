import 'babel-polyfill';
import 'whatwg-fetch';
import React from "react";
import ReactDOM from 'react-dom';
import { Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from 'utils/theme';
import history from 'utils/history';
import stores from 'stores';
import App from './app.js';

window.hotReloading = module.hot && module.hot.data;

ReactDOM.render(
  <Provider mobx={stores}>
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle/>
        <Router history={history}>
          <Switch>
            <Route component={App}/>
          </Switch>
        </Router>
      </React.Fragment>
    </ThemeProvider>
  </Provider>,
  document.getElementById('app-root'),
);
