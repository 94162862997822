class Subscription {
	constructor(API) {
		this.API = API;
		this.apiPath = process.env.SUBSCRIPTION_API_URL;
	}

	editShopPlan = (shopId, plan) => {
		const url = `${this.apiPath}/plan/edit`;
		const params = {
			key: this.API.getToken(),
			shopId,
			plan
		};
		return this.API.httpPost(url, params);
	}

	bindShopCreditCard = (shopId, prime, cardHolder, email, phone) => {
		const url = `${this.apiPath}/payment/card/bind`;
		const params = {
			key: this.API.getToken(),
			shopId, prime, cardHolder, email, phone
		};
		return this.API.httpPost(url, params);
	}

	removeShopCreditCard = (shopId, lastFour) => {
		const url = `${this.apiPath}/payment/card/remove`;
		const params = {
			key: this.API.getToken(),
			shopId, lastFour
		};
		return this.API.httpPost(url, params);
	}

	fetchShopMonthlyBill = (shopId) => {
		const url = `${this.apiPath}/bill/monthly/bill/history`;
		const params = {
			key: this.API.getToken(),
			shopId, 
		};
		return this.API.httpPost(url, params);
	}

	payShopMonthlyBillDirectly = (shopId, billId) => {
		const url = `${this.apiPath}/bill/monthly/bill/pay/direct`;
		const params = {
			key: this.API.getToken(),
			shopId,
			billId 
		};
		return this.API.httpPost(url, params);
	}
}


export default Subscription;
