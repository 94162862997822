export const scrollTo = (to, duration) => {
  let start = getScrollTop();
  let distance = to - start;
  let time = 0;

  duration = duration / 5;

  let easeInOutQuart = (t, b, c, d) => {
    t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b
  }

  let smoothScroll = () => {
    time+=1;
    window.scrollTo(0, easeInOutQuart(time, start, distance, duration));

    if(time < duration) {
      setTimeout(smoothScroll, 1);
    }
  };

  smoothScroll();
}

export const getScrollTop = () => {
  return window.pageYOffset
    || (document.documentElement
      || document.body.parentNode
      || document.body
    ).scrollTop;
};

export const getScrollHeight = () => {
  return (document.documentElement
    || document.body.parentNode
    || document.body
  ).offsetHeight
}

export const parseDate = (dt, tz) => {
  if(typeof dt === 'string') {
    dt = dt.replace(/-/g, '/');

    if(tz && /^(\+|\-)[0-9]{2}:[0-9]{2}$/.test(tz)) {
      dt = dt.replace(/\//g, '-').replace(' ', 'T') + tz;
    }
  }

  return new Date(dt);
}

export const getDatetimeString = (dt, format) => {
  let datetime = parseDate(dt);
  let datetimeString = '';

  if(datetime && datetime != 'Invalid Date') {
    let year = (datetime.getFullYear()) + '';
    let month = (datetime.getMonth() + 1) + '';
    let date = (datetime.getDate()) + '';
    let hours = (datetime.getHours()) + '';
    let minutes = (datetime.getMinutes()) + '';
    let seconds = (datetime.getSeconds()) + '';

    if(!format) {
      datetimeString = `${year}-`
       + `${('00' + month).substr(-2)}-`
       + `${('00' + date).substr(-2)} `
       + `${('00' + hours).substr(-2)}:`
       + `${('00' + minutes).substr(-2)}:`
       + `${('00' + seconds).substr(-2)}`;
    }
    else {
      if(format.indexOf('YYYY') !== -1) {
        format = format.replace(/YYYY/g, year);
      }

      if(format.indexOf('YY') !== -1) {
        format = format.replace(/YY/g, year.substr(-2));
      }

      if(format.indexOf('MM') !== -1) {
        format = format.replace(/MM/g, ('00' + month).substr(-2));
      }

      if(format.indexOf('M') !== -1) {
        format = format.replace(/M/g, month);
      }

      if(format.indexOf('DD') !== -1) {
        format = format.replace(/DD/g, ('00' + date).substr(-2));
      }

      if(format.indexOf('D') !== -1) {
        format = format.replace(/D/g, date);
      }

      if(format.indexOf('hh') !== -1) {
        format = format.replace(/hh/g, ('00' + hours).substr(-2));
      }

      if(format.indexOf('h') !== -1) {
        format = format.replace(/h/g, date);
      }

      if(format.indexOf('mm') !== -1) {
        format = format.replace(/mm/g, ('00' + minutes).substr(-2));
      }

      if(format.indexOf('m') !== -1) {
        format = format.replace(/m/g, date);
      }

      if(format.indexOf('ss') !== -1) {
        format = format.replace(/ss/g, ('00' + seconds).substr(-2));
      }

      if(format.indexOf('s') !== -1) {
        format = format.replace(/s/g, date);
      }

      datetimeString = format;
    }
  }

  return datetimeString;
}

export const getWeekdayString = (weekdayNumber, shortForm) => {
  const weekdayStrings = ['週一', '週二', '週三', '週四', '週五', '週六', '週日'];
  if(shortForm) {
    return (weekdayStrings[weekdayNumber] || '').replace('週', '');
  }
  else {
    return weekdayStrings[weekdayNumber] || '';
  }
}

export const getWeekdayNumber = (weekdayString) => {
  const getWeekdayNumberMap = {
    '週一': 0, '週二': 1, '週三': 2, '週四': 3, '週五': 4, '週六': 5, '週日': 6,
  };
  if(weekdayString.indexOf('週') === -1) {
    weekdayString = '週' + weekdayString;
  }
  return getWeekdayNumberMap[weekdayString];
}

export const getGmtLocalTime = (dt, gmt) => {
  let datetime = parseDate(dt);
  let localtime = datetime.setHours(datetime.getHours() + gmt);
  return localtime;
}

export const getGmtLocalTimeString = (dt, gmt, format) => {
  let datetime = parseDate(dt);
  let localtime = datetime.setHours(datetime.getHours() + gmt);
	const result = getDatetimeString(localtime, format)
  return result;
}

export const createLiffUrl = async (path) => {
	try {
		let origineUrl = 'https://' + window.location.hostname
		if (window.location.hostname === 'localhost') {
			origineUrl = 'https://dev.taketla.com'
		}
		const liffUrl = await liff.permanentLink.createUrlBy(origineUrl)
		return liffUrl + path
	} catch (error) {
		console.log(error)
	}
}

export const queryParams = (queryString) => {
  let params = {};
  queryString = queryString || window.location.search;

  if(queryString.length > 0 && queryString[0] === '?') {
    queryString = queryString.slice(1, queryString.length);
  }

  if(queryString.trim()) {
    let qsList = queryString.split('&');

    if(qsList.length > 0) {
        qsList.forEach(qs => {
          let qsParts = qs.split('=');
          let key = qsParts[0];
          let value = qsParts[1] ? decodeURIComponent(qsParts[1]) : undefined;

          if(value === 'true' || value === 'True') {
            value = true;
          }

          if(value === 'false' || value === 'False') {
            value = false;
          }

          if(params[key]) {
            if(params[key].join) {
              params[key].push(value);
            }
            else {
              params[key] = [params[key], value]
            }
          }
          else {
            params[key] = value;
          }
        }
      )
    }
  }

  return params;
}

export const setCookie = (name, value, maxAge, path) => {
  document.cookie = `${name}=${value}; max-age=${maxAge}; path=${path}`;
}

export const getCookie = (name) => {
  const re = new RegExp(`(?:(?:^|.*;\\s*)${name}\\s*\\=\\s*([^;]*).*$)|^.*$`);
  return document.cookie.replace(re, '$1');
}

export const getPreviewImage = async (file) => {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();

    fileReader.onload = () => {
      let objUrl = URL.createObjectURL(file);
      let base64 = fileReader.result;
      let img = new Image();

      img.onload = () => {
        EXIF.getData(img, () => {
          let orientation = EXIF.getTag(img, 'Orientation');

          if(orientation === 1) {
            resolve({ objUrl, base64 });
          }
          else {
            let w = img.width;
            let h = img.height;
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            if(w > 1280) {
              h = 1280 / w * h;
              w = 1280;
            }

            if(orientation > 4 && orientation < 9) {
              canvas.width = h;
              canvas.height = w;
            }
            else {
              canvas.width = w;
              canvas.height = h;
            }

            switch (orientation) {
              case 2:
                ctx.transform(-1, 0, 0, 1, w, 0);
                break;
              case 3:
                ctx.transform(-1, 0, 0, -1, w, h);
                break;
              case 4:
                ctx.transform(1, 0, 0, -1, 0, h);
                break;
              case 5:
                ctx.transform(0, 1, 1, 0, 0, 0);
                break;
              case 6:
                ctx.transform(0, 1, -1, 0, h, 0);
                break;
              case 7:
                ctx.transform(0, -1, -1, 0, h, w);
                break;
              case 8:
                ctx.transform(0, -1, 1, 0, 0, w);
                break;
            }

            ctx.drawImage(img, 0, 0, w, h);

            canvas.toBlob((blob) => {
              resolve({
                objUrl: URL.createObjectURL(blob),
                base64: canvas.toDataURL('image/jpeg', 0.7),
              });
            }, 'image/jpeg', 0.7)
          }
        });
      }

      img.src = objUrl;
    };
    fileReader.onerror = () => { reject(); };
    fileReader.readAsDataURL(file);
  });
}

export const fileToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();

    fileReader.onload = () => {
      let objUrl = URL.createObjectURL(file);
      let base64 = fileReader.result;
      let img = new Image();

      img.onload = () => {
        EXIF.getData(img, () => {
          let orientation = EXIF.getTag(img, 'Orientation');

          if(orientation === 1) {
            resolve(base64);
          }
          else {
            let w = img.width;
            let h = img.height;
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            if(w > 1280) {
              h = 1280 / w * h;
              w = 1280;
            }

            if(orientation > 4 && orientation < 9) {
              canvas.width = h;
              canvas.height = w;
            }
            else {
              canvas.width = w;
              canvas.height = h;
            }

            switch (orientation) {
              case 2:
                ctx.transform(-1, 0, 0, 1, w, 0);
                break;
              case 3:
                ctx.transform(-1, 0, 0, -1, w, h);
                break;
              case 4:
                ctx.transform(1, 0, 0, -1, 0, h);
                break;
              case 5:
                ctx.transform(0, 1, 1, 0, 0, 0);
                break;
              case 6:
                ctx.transform(0, 1, -1, 0, h, 0);
                break;
              case 7:
                ctx.transform(0, -1, -1, 0, h, w);
                break;
              case 8:
                ctx.transform(0, -1, 1, 0, 0, w);
                break;
            }

            ctx.drawImage(img, 0, 0, w, h);

            resolve(canvas.toDataURL('image/jpeg', 0.7));
          }
        });
      }

      img.src = objUrl;
    };
    fileReader.onerror = () => { reject(); };
    fileReader.readAsDataURL(file);
  });
}

export const getTextLength = (text) => {
  let length = 0;
  if(text) {
    [...text].forEach(char => {
      const bytes = unescape(encodeURIComponent(char)).length;
      length += (bytes === 3 ? 2 : 1);
    });
  }
  return length / 2;
}

// groupBy return opject whose
// key is "key" in params 
// and value is array of group objects
export const groupBy = (xs, key) => {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const useStateWithPromise = (initialState) => {
  const [state, setState] = useState(initialState);
  const resolverRef = useRef(null);

  useEffect(() => {
    if (resolverRef.current) {
      resolverRef.current(state);
      resolverRef.current = null;
    }
  }, [resolverRef.current, state]);

  const handleSetState = useCallback((stateAction) => {
    setState(stateAction);
    return new Promise(resolve => {
      resolverRef.current = resolve;
    });
  }, [setState])

  return [state, handleSetState];
};

export const setStoreValue = (store, path, value) => {
	let keys = path.split('.');
	let targetKey;
	let parent = store;
	if (keys.length === 1) targetKey = keys[0];
	if (keys.length > 1) {
		targetKey = keys.slice(-1)[0];
		keys = keys.slice(0, keys.length - 1);
		keys.forEach(key => parent = parent[key]);
	}
	if (parent && targetKey && parent[targetKey] !== undefined) {
		parent[targetKey] = value;
	}
};

export const toggleStoreValue = (store, path, value, comparisonKey) => {
	let keys = path.split('.');
	let targetKey;
	let parent = store;

	if (keys.length === 1) targetKey = keys[0];
	if (keys.length > 1) {
		targetKey = keys.slice(-1)[0];
		keys = keys.slice(0, keys.length - 1);
		keys.forEach(key => parent = parent[key]);
	}

	if (parent && targetKey && parent[targetKey] !== undefined) {

		if (typeof parent[targetKey] === 'boolean') {
			parent[targetKey] = (typeof value === 'boolean') ? value : !parent[targetKey];
		}

		if (parent[targetKey] === 1 || parent[targetKey] === 0) {
			parent[targetKey] = 1 - parent[targetKey];
		}

		if (Array.isArray(parent[targetKey])) {
			let found = true;

			if (!!comparisonKey && typeof comparisonKey === 'string') {
				found = parent[targetKey].some(v => v[comparisonKey] === value[comparisonKey])
			} else found = parent[targetKey].some(v => v === value);

			if (found) {
				if (!!comparisonKey && typeof comparisonKey === 'string') {
					parent[targetKey] = parent[targetKey].filter(v => v[comparisonKey] !== value[comparisonKey]);
				} else parent[targetKey] = parent[targetKey].filter(v => v !== value);
			}
			else parent[targetKey].push(value);
		}
	}
};

export const validatePwd = (pwd) => {
	return /^[a-zA-Z0-9]{8,}$/.test(pwd)
		&& /[\A-Z]/i.test(pwd)
		&& /\d/.test(pwd);
}

export const validateEmail = (email) => {
	const emailRgx = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/
	return emailRgx.test(email)
}

export const clipboard = async (message) => {
	try {
		await navigator.clipboard.writeText(message);
		return true
	} catch (err) {
		console.error("Unable to copy text to clipboard:", err);
	}
};

export default {
  scrollTo,
  getScrollTop,
  getScrollHeight,
  parseDate,
  getDatetimeString,
  getWeekdayString,
  getWeekdayNumber,
  getGmtLocalTime,
	getGmtLocalTimeString,
	createLiffUrl,
  queryParams,
  setCookie,
  getCookie,
  fileToBase64,
  getPreviewImage,
  getTextLength,
  groupBy,
  useStateWithPromise,
	setStoreValue,
	toggleStoreValue,
	validatePwd,
	clipboard
};
