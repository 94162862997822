import { makeAutoObservable } from 'mobx';

class AuthState {
  constructor(RootStore) {
    makeAutoObservable(this)
    this.r = RootStore
  };

  validUser = false;
  oauthType = '';
  authenticated = false;
	isStore = false;
	validStore = false;
	isEmployee = false;

	line = {
		liffId: '',
	};

  user = {
    id: '',
    name: '',
    account: '',
    avatarUrl: '',
    email: '',
    phone: '',
		employeeType: ''
  };

	store = {
		id: '',
		name: '',
		company: '',
		taxId: '',
		employeeId: '',
		liffId: '',
		chainId: '',
		url: '',
		branch: null,
		signed: false,
		pendingBranches: null,
		planType: '',
	}

  financial = {
    creditCard:{
      lastFour:''
    }
  };

}

export default AuthState;
