class PAYMENT {
	constructor(API) {
		this.API = API;
		this.apiPath = process.env.PAYMENT_API_URL;
	}

	submitTapPayPayment = async (tappayOrder, tappayData) => {
		const url = `${this.apiPath}/purchase/tappay`;
		const params = {
			key: this.API.getToken(),
			tappayOrder,
			tappayData,
		};
		return this.API.httpPost(url, params);
	}

	submitLinePayPayment = async (tappayOrder, tappayData, lineData) => {
		const url = `${this.apiPath}/purchase/linepay`;
		const params = {
			key: this.API.getToken(),
			tappayOrder,
			tappayData,
			lineData
		};
		return this.API.httpPost(url, params);
	}

	successTapPayPayment = async (order_number) => {
		const url = `${this.apiPath}/purchase/notify`;
		const params = {
			order_number,
			status: 0
		};
		return this.API.httpPost(url, params);
	}

	submitFreeTicketPayment = async (order, lineData) => {
		const url = `${this.apiPath}/purchase/free`;
		const params = {
			key: this.API.getToken(),
			order,
			lineData
		};
		return this.API.httpPost(url, params);
	}

	fetchOrder = async (paymentId) => {
		const url = `${this.apiPath}/order/${paymentId}`;
		const params = {
			key: this.API.getToken(),
		};
		return this.API.httpPost(url, params);
	}

	fetchUserOrders = async () => {
		const url = `${this.apiPath}/orders/user`;
		const params = {
			key: this.API.getToken(),
		};
		return this.API.httpPost(url, params);
	}

	fetchShopOrders = async (shopId, query, page) => {
		const url = `${this.apiPath}/orders/shop`;
		const params = {
			key: this.API.getToken(),
			shopId,
			page,
			query
		};
		return this.API.httpPost(url, params);
	}

	fetchShopTicketLogs = async (shopId, query, page) => {
		const url = `${this.apiPath}/tickets/shop`;
		const params = {
			key: this.API.getToken(),
			shopId,
			page,
			query
		};
		return this.API.httpPost(url, params);
	}

	fetchUserTicketLogs = async () => {
		const url = `${this.apiPath}/tickets/user`;
		const params = {
			key: this.API.getToken(),
		};
		return this.API.httpPost(url, params);
	}


	getPromotionCodeRule = async (shopId, codeId) => {
		const url = `${this.apiPath}/promotion/check`;
		const params = {
			shopId,
			codeId
		};
		return this.API.httpPost(url, params);
	}

	getShopPromotionCodes = async (storeId) => {
		const url = `${this.apiPath}/promotion/shop/${storeId}`;
		return this.API.httpGet(url);
	}

	togglePromotionCodeValid = async (storeId, codeId) => {
		const url = `${this.apiPath}/promotion/valid`;
		const params = {
			key: this.API.getToken(),
			shopId: storeId,
			codeId: codeId
		};
		return this.API.httpPost(url, params);
	}

	editPromotionCode = async (storeId, codeData) => {
		const url = `${this.apiPath}/promotion/edit`;
		const params = {
			key: this.API.getToken(),
			shopId: storeId,
			codeData
		};
		return this.API.httpPut(url, params);
	}

	cancelOrder = async (paymentId, shopId) => {
		const url = `${this.apiPath}/refund/tappay`;
		const params = {
			key: this.API.getToken(),
			shopId,
			paymentId,
		};
		return this.API.httpPost(url, params);
	}

	invalidInvoice = async (shopId, invoiceNumber) => {
		const url = `${this.apiPath}/invoice/invalid`;
		const params = {
			key: this.API.getToken(),
			invoiceNumber,
			shopId,
		};
		return this.API.httpPost(url, params);
	}

	resendInvoice = async (paymentId) => {
		const url = `${this.apiPath}/invoice/resend`;
		const params = {
			key: this.API.getToken(),
			paymentId,
		};
		return this.API.httpPost(url, params);
	}

	getUserCreditCard = async (appId) => {
		const url = `${this.apiPath}/getcard/tappay`;
		const params = {
			key: this.API.getToken(),
			appId,
		};
		return this.API.httpPost(url, params);
	}
}

export default PAYMENT;
