import { makeAutoObservable } from 'mobx';
import history from 'utils/history';
import helpers from 'utils/helpers';
import API from 'api';


class ExchangeStore {
	constructor(RootStore) {
		makeAutoObservable(this)
		this.r = RootStore
	};

	fetchEventShopByCode = async (code, shopId) => {
		return await this.r.app.asyncWorker(async () => {
			let shop = await API.Exchange.fetchEventShopByCode(code, shopId);
			shop = shop ? {
				name: shop.shop_name,
				companyName: shop.company_name,
				id: shop.shop_id,
				cover: shop.shop_cover || ''
			} : null
			return shop;
		});
	};

	submitExchange = async (ticket, shopId, customerPk) => {
		return await this.r.app.asyncWorker(async () => {
			if (!this.r.auth.validUser) {
				this.r.user.checkLineLogin("請使用 Line 驗證")
				return
			}
			//userPk: ticket user id;
			const result = await API.Exchange.submitExchange(ticket, shopId, customerPk);
			if (result) {
				this.r.ui.popup.message = `兌換成功！您兌換的品項為 ${ticket.name} x 1，剩餘數量 ${ticket.remain - 1}。`;
				this.r.ui.popup.callback = (() => {
					if (liff.isInClient() && !this.r.auth.isStore) {
						liff.sendMessages([{
							"type": "text",
							"text": `兌換成功！您兌換的品項為 ${ticket.name} x 1，剩餘數量 ${ticket.remain - 1}。`
						}, {
							"type": "text",
							"text": "再次感謝您的支持，如有任何問題請洽客服人員:D"
						}, {
							"type": "sticker",
							"packageId": "11537",
							"stickerId": "52002734"
						}
						]).then(() => liff.closeWindow()).catch((err) => {
							console.log(err)
						});
					}
				});
				this.r.ui.popup.current = 'message';
			}
			return result;
		});
	};

	fetchMemberTicket = async (token) => {
		return await this.r.app.asyncWorker(async () => {
			let ticket = await API.Exchange.fetchMemberTicketByToken(token);
			ticket = ticket ? {
				userPk: ticket.user_pk || '',
				memberTicketId: ticket.id || '',
				ticketId: ticket.ticket_id || '',
				shopId: ticket.shop_id || ticket.pr_shop_id || '',
				prShopId: ticket.pr_shop_id || '',
				name: ticket.name || '',
				quantity: ticket.quantity || 0,
				remain: ticket.remain || 0,
				remark: ticket.remark || '',
				cover: ticket.cover || '',
				token: ticket.token || '',
				isValid: ticket.is_valid || 0,
				isShared: ticket.is_shared || 0,
				logTime: ticket.log_time || '',
				startTime: ticket.start_time || '',
				endTime: ticket.end_time || '',
			} : null
			return ticket;
		});
	};

	addPrTicket = async (shopid, ticketData) => {
		return await this.r.app.asyncWorker(async () => {
			return await API.Exchange.addPrTicket(shopid, ticketData);
		});
	}

	getPrTickets = async (productId) => {
		return await this.r.app.asyncWorker(async () => {
			let tickets = await API.Exchange.getPrTickets(productId);
			tickets = tickets ? tickets.map(t => ({
				ticketId: t.ticket_id || '',
				isTaken: t.is_taken ? 'V' : '',
				quota: t.quota || 1,
				remark: t.remark || '',
				institution: t.institution || '',
				takerName: t.name || '',
				takerMail: t.email || '',
				takerPhone: t.phone || '',
				takeTime: t.log_time || ''
			})) : null
			return tickets
		});
	};

	takePrTicket = async (ticketData, lineUserId) => {
		await this.r.app.asyncWorker(async () => {
			const taken = await API.Exchange.takePrTicket(ticketData, lineUserId);
			if(taken){
				const success = await API.Exchange.takePrTicketSuccess(ticketData, lineUserId, taken?.shopId);
				if (success) {
					this.r.ui.popup.title = '完成領取';
					this.r.ui.popup.message = !!taken.isTaken ? 
						'活動票券已重新寄送至您的 Email，前次寄送之票券將失去效用，請以此次為準。' : 
						'活動票券已寄至您的 Email。';
					this.r.ui.popup.current = 'message'
				}
			}
		});
	};

	shareMemberTicket = async (token, newToken, shareAmt) => {
		return await this.r.app.asyncWorker(async () => {
			let success = await API.Exchange.shareMemberTicketByToken(token, newToken, shareAmt);
			if (success) {
				this.r.ui.popup.message = '分享成功。';
				this.r.ui.popup.current = 'message';
			}
		});
	};
	

	rejectMemberTicket = async (token, message) => {
		return await this.r.app.asyncWorker(async () => {
			const rejectMessage = message || '謝謝你的好意，不用送我沒有關係🥺'
			const done = await API.Exchange.rejectMemberTicketByToken(token)
			//redeem: cancel share process
			if (done) {
				if (!liff.isInClient()) {
					this.r.ui.popup.message = '已退回禮物。';
					this.r.ui.popup.current = 'message';
				}
				liff.closeWindow()
				this.r.plugin.sendLineMessage(["已退回禮物 🥺", rejectMessage])
			}
		});
	};
}

export default ExchangeStore;