import { makeAutoObservable } from 'mobx';
import history from 'utils/history';
import helpers from 'utils/helpers';
import API from 'api';


class ShopStore {
	constructor(RootStore) {
		makeAutoObservable(this)
		this.r = RootStore
	};

	fetchShopSettings = async (shopId) => {
		return await this.r.app.asyncWorker(async () => {
			let shop = await API.Shop.fetchShopSettings(shopId);
			shop = {
				id: shop.shop_id || '',
				name: shop.shop_name || '',
				company: shop.company_name || '',
				taxId: shop.tax_id || '',
				taxInvoice: shop.tax_invoice || 0,
				pixel: shop.pixel_id || '',
				liffId: shop.liff_id || '',
				email: shop.email || '',
				phone: shop.phone || '',
				isSigned: shop.is_signed || 0,
				customerEmailSettings: {
					mail: shop.customerEmailSettings?.mail || '',
					title: shop.customerEmailSettings?.title || '',
					isValid: shop.customerEmailSettings?.is_valid || false,
				},
				tappaySettings: {
					appId: shop.tappaySettings?.tappay_app_id || '',
					appKey: shop.tappaySettings?.tappay_app_key || '',
				},
				plan: {
					planType: shop.plan_type || '',
					monthlyFee: shop.monthly_fee || 8800,
					freeMonthlyTicketAmount: shop.free_monthly_ticket_amount || 500,
					perTicketFee: shop.per_ticket_fee || 2,
					paymentFeePercentage: shop.payment_fee_percentage || 0.08,
					invoiceFee: shop.per_invoice_fee || 10,
					customerPayment: shop.customer_payment || 0,
					customerInvoice: shop.customer_invoice || 0,
					customerEmail: shop.customer_email || 0,
					customerLine: shop.customer_line || 0,
					invoiceService: shop.invoice_service || 0,
					isValid: shop.is_valid || 0,
					cardValid: shop.card_valid || 0,
					cardLastFour: shop.card_last_four || ''
				}
			};
			return shop
		});
	};

	fetchStoreBranches = async (storeId) => {
		return await this.r.app.asyncWorker(async () => {
			let branches = await API.Shop.fetchStoreBranches(storeId);
			branches = branches
				? branches.map(branch => ({
					id: branch.shop_id || '',
					name: branch.shop_name || '',
					cover: branch.shop_cover || '',
					url: `/shop/info/${branch.id}`,
					signed: branch.is_signed || false,
				}))
				: [];
			return branches;
		});
	};

	changeBranch = (branchId, branchName) => {
		this.r.auth.store.id = branchId;
		this.r.auth.store.branchId = branchId;
		this.r.auth.store.name = branchName;
		this.r.auth.store.url = `/shop/info/${branchId}`;
	};

	fetchShopEmployees = async (shopId) => {
		return await this.r.app.asyncWorker(async () => {
			let employees = await API.Shop.fetchShopEmployees(shopId);
			employees = employees
				? employees.map(employee =>
				({
					name: employee.nickname || '',
					id: employee.employee_pk || '',
					isValid: employee.is_valid || 0,
					lineNotification: employee.line_notification || 0,
					type: employee.type || 'employee',
				})) : null;

			return employees;
		});
	};

	removeShopEmployee = async (shopId, employeeId) => {
		return await this.r.app.asyncWorker(async () => {
			await API.Shop.removeShopEmployee(shopId, employeeId);
		});
	};

	fetchShopPartners = async (shopId) => {
		return await this.r.app.asyncWorker(async () => {
			let partners = await API.Shop.fetchShopPartners(shopId);
			partners = partners
				? partners.map(partner =>
				({
					partnerName: partner.shop_name || '',
					companyName: partner.company_name || '',
					partnerId: partner.partner_id || '',
					partnerCode: partner.partner_code || '----',
					isValid: partner.is_valid || 0,
				})) : null;
			return partners;
		});
	};

	editShopPixelId = async (shopId, pixelId) => {
		return await this.r.app.asyncWorker(async () => {
			return await API.Shop.updateShopPixelId(shopId, pixelId);
		});
	};

	editShopCustomerEmailSettings = async (shopId, mail, title) => {
		return await this.r.app.asyncWorker(async () => {
			return await API.Shop.editShopCustomerEmailSettings(shopId, mail, title);
		});
	};

	fetchShopPromotionCodes = async (shopId) => {
		return await this.r.app.asyncWorker(async () => {
			let codes = await API.Payment.getShopPromotionCodes(shopId);
			codes = codes
				? codes.map(c =>
				({
					isValid: c.is_valid || 0,
					code: c.code || '',
					newCode: c.code || '',
					type: c.type || '',
					threshold: c.threshold || 0,
					discount: c.type === 'cash' ? c.discount : (100 - (c.discount * 100)),
					endTime: c.end_time ? new Date(c.end_time) : null,
					remain: c.remain === 0 ? 0 : c.remain || '',
					remark: c.remark || '',
				})) : [];

			return codes;
		});
	};

	togglePromotionCodeValid = async (shopId, codeId) => {
		return await this.r.app.asyncWorker(async () => {
			await API.Payment.togglePromotionCodeValid(shopId, codeId);
		});
	};

	editPromotionCode = async (shopId, codeData) => {
		return await this.r.app.asyncWorker(async () => {
			return await API.Payment.editPromotionCode(shopId, codeData);
		});
	};

	addShopEmployee = async (shopId, employeeType, isStore) => {
		return await this.r.app.asyncWorker(async () => {
			await API.Shop.addShopEmployee(shopId, employeeType);
			if (!isStore) {
				this.r.ui.popup.message = '已送出請求'
				this.r.ui.popup.callback = () => {
					history.push('/main')
					liff.closeWindow()
				};
				this.r.ui.popup.current = 'message';
			}
		});
	};

	toggleShopEmployeeValid = async (shopId, employeePk, employeeType, lineNotification ,toValid) => {
		return await this.r.app.asyncWorker(async () => {
			await API.Shop.toggleShopEmployeeValid(shopId, employeePk, employeeType, lineNotification, toValid);
		});
	};

	removeShopEmployee = async (shopId, employeePk) => {
		return await this.r.app.asyncWorker(async () => {
			await API.Shop.removeShopEmployee(shopId, employeePk);
		});
	};

	addShopPartner = async (shopId, sourceId) => {
		return await this.r.app.asyncWorker(async () => {
			await API.Shop.addShopPartner(shopId, sourceId);
			this.r.ui.popup.message = '已送出請求'
			this.r.ui.popup.current = 'message';
		});
	};

	removeShopPartner = async (shopId, partnerId) => {
		return await this.r.app.asyncWorker(async () => {
			await API.Shop.removeShopPartner(shopId, partnerId);
		});
	};

	editShopPartner = async (shopId, partnerId, partnerData) => {
		return await this.r.app.asyncWorker(async () => {
			await API.Shop.editShopPartner(shopId, partnerId, partnerData);
		});
	};

	fetchStorePixel = async (shopId) => {
		await this.r.app.asyncWorker(async () => {
			let shop = await API.Shop.fetchShopSettings(shopId);
			if (shop) {
				const pixel = shop.pixel_id || ''
				if (pixel) {
					fbq("init", pixel);
					fbq('track', 'PageView');
				}
			}
		});
	};
}

export default ShopStore;