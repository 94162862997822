import React from 'react';
import { MobXProviderContext } from 'mobx-react';
import { configure } from "mobx"
import AppStore from './AppStore';
import AuthState from './AuthState';
import UiState from './UiState';  
import UserStore from './UserStore';
import ShopStore from './ShopStore';
import ProductStore from './ProductStore';
import OrderStore from './OrderStore';
import PluginStore from './PluginStore';
import ExchangeStore from './ExchangeStore';
import BazaarStore from './BazaarStore';

configure({ enforceActions: "never", })

class Stores {
  constructor() {
    this.app = new AppStore(this);
    this.auth = new AuthState(this);
    this.ui = new UiState(this);
    this.user = new UserStore(this);
    this.shop = new ShopStore(this);
    this.product = new ProductStore(this);
    this.order = new OrderStore(this);
    this.plugin = new PluginStore(this);
    this.exchange = new ExchangeStore(this);
    this.bazaar = new BazaarStore(this);
  }
}

export function useStores() {
  return React.useContext(MobXProviderContext)
}

export default new Stores();
