import { makeAutoObservable } from 'mobx';
import helpers from 'utils/helpers';
import API from 'api'

class BazaarStore {
	constructor(RootStore) {
		makeAutoObservable(this)
		this.r = RootStore
	};

	product = null;
	storeProducts = [];

	importBazaarProductFromMall = async (shopId) => {
		await this.r.app.asyncWorker(async () => {
			const result = await API.Bazaar.importBazaarProductFromMall(shopId);
			if (result) {
				this.fetchStoreProducts(shopId)
				this.r.ui.popup.message = '匯入完成';
				this.r.ui.popup.current = 'message'
			}
		});
	};

	fetchStoreProducts = async (storeId) => {
		return await this.r.app.asyncWorker(async () => {
			let storeProducts = await API.Bazaar.fetchStoreProducts(storeId);
			storeProducts = storeProducts
				? storeProducts.map(storeProduct =>
				({
					id: storeProduct.id || '',
					name: storeProduct.name || '',
					price: storeProduct.price || 0,
					status: storeProduct.on_sale || 0,
					remain: storeProduct.remain || 0,
					updatedTime: storeProduct.updated_time || 0,
					cover: storeProduct.cover || '',
					checked: false,
				})) : [];
			this.storeProducts = storeProducts;
			return storeProducts
		});
	};

	deleteProduct = async (productId) => {
		await this.r.app.asyncWorker(async () => {
			await API.Bazaar.deleteProduct(productId);
			this.storeProducts = this.storeProducts.filter(product => product.id !== productId);
		});
	}

	fetchProduct = async (productId) => {
		return await this.r.app.asyncWorker(async () => {
			let product = await API.Bazaar.fetchProduct(productId);
			product = product
				? {
					id: product.id || '',
					name: product.name || '',
					price: product.price || 0,
					remain: product.remain === 9999 ? '' : product.remain || 0,
					cover: product.cover || 0,
				}
				: undefined;
			this.product = product;
			return product;
		});
	};

	editProduct = async (productId, storeId, productData, uploadCoverBase64) => {
		return await this.r.app.asyncWorker(async () => {

			if (uploadCoverBase64) {
				const uploadedUrl = await API.Product.uploadPhoto(storeId, uploadCoverBase64);
				if (uploadedUrl) productData.cover = uploadedUrl;
			};

			const resultId = await API.Bazaar.addProduct(productId, storeId, productData);
			productId = resultId;
			return productId;
		});
	};

	fetchStoreOrderHistory = async (query, page) => {
		return await this.r.app.asyncWorker(async () => {
			const shopId = this.r.auth.store.id
			let orders = await API.Bazaar.fetchOrderHistory(shopId, query, page);
			orders = orders
				? orders.map(order =>
				({
					id: order.id || '',
					paymentId: order.payment_id || '',
					createdTime: helpers.getGmtLocalTime(new Date(order.created_time) || '', 8) || '',
					status: order.status,
					remark: order.remark,
					recipientPhone: order.user_phone,
					invoice: {
						number: order.invoice_number,
						status: order.invoice_status,
					},
					itemCost: order.cost_original || 0,
					costActual: order.cost_actual || 0,
					items: order.items ? order.items.map(c => ({
						cover: c.cover || '',
						name: c.name || '',
						price: c.price || 0,
						discountPrice: c.price || 0,
						quantity: c.quantity || 1,
					})) : [],
				})) : [];
			return orders;
		});
	};

	downloadShopOrders = async (query, page) => {
		return await this.r.app.asyncWorker(async () => {
			const shopId = this.r.auth.store.id
			let logs = await API.Bazaar.fetchOrderHistory(shopId, query, page)
			logs = logs
				? logs.map(log => ({
					'訂單編號': log.payment_id || '--',
					'訂單狀態': log.status || '--',
					'訂單金額': log.cost_actual || '--',
					'成立時間': helpers.getGmtLocalTimeString(new Date(log.created_time) || '', 8, 'YYYY-MM-DD hh:mm:ss') || '--',
					'用戶手機': log.user_phone || '--',
					'發票號碼': log.invoice_number || '--',
					'發票狀態': log.invoice_status || '--',
					'訂單商品': log.item_list || '--',
					'訂單備註': log.remark || '--'
				}))
				: null;
			return logs
		});
	};


	cancelBazaarOrder = async (paymentId) => {
		return await this.r.app.asyncWorker(async () => {
			const shopId = this.r.auth.store.id
			let result = await API.Bazaar.cancelOrder(paymentId, shopId);
			if (result) {
				this.r.ui.popup.message = '訂單取消與退款成功'
				this.r.ui.popup.current = 'message';
			}
			return result
		});
	};
}

export default BazaarStore;