class Product {
	constructor(API) {
		this.API = API;
		this.apiPath = process.env.PRODUCT_API_URL;
	}

	fetchProduct = (productId) => {
		const url = `${this.apiPath}/${productId}`;
		return this.API.httpGet(url);
	}

	fetchStoreProducts = (shopId) => {
		const url = `${this.apiPath}/list/${shopId}`;
		return this.API.httpGet(url);
	}

	fetchProductTickets = (productId) => {
		const url = `${this.apiPath}/ticket/list/${productId}`;
		return this.API.httpGet(url);
	}

	deleteProductTicket = (ticketId) => {
		const url = `${this.apiPath}/ticket/${ticketId}`;
		const params = {
			key: this.API.getToken(),
		};
		return this.API.httpDelete(url, params);
	}

	deleteProduct = (productId) => {
		const url = `${this.apiPath}/${productId}`;
		const params = {
			key: this.API.getToken(),
		};
		return this.API.httpDelete(url, params);
	}

	setSaleProduct = (productId) => {
		const url = `${this.apiPath}/sale/${productId}`;
		const params = {
			key: this.API.getToken(),
		};
		return this.API.httpPut(url, params);
	}

	uploadPhoto = (storeId, base64) => {
    const url = `${this.apiPath}/images/upload`;
    const params = {
      key: this.API.getToken(),
      id: storeId,
      image: base64,
    };
    return this.API.httpPost(url, params);
  }

	editProduct = async (productId, storeId, productData, specificationsData) => {
		const url = `${this.apiPath}/edit`;
		const params = {
			key: this.API.getToken(),
			productId: productId,
			shopId: storeId,
			productData: productData,
			specificationsData
		};
		return this.API.httpPost(url, params);
	}

	editProductTicket = async (ticketId, productId, ticketData) => {
		const url = `${this.apiPath}/ticket/edit`;
		const params = {
			key: this.API.getToken(),
			ticketId, 
			productId, 
			ticketData
		};
		return this.API.httpPost(url, params);
	}
}

export default Product;
